// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Noto Sans JP", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Grids

$grid-breakpoints: (
  // 極小画面 / 縦モバイル
  xs: 0,
  // 小画面 / 横モバイル
  sm: 576px,
  // 中画面 / タブレット
  md: 768px,
  // 大画面 / デスクトップ
  lg: 992px,
  // 特大画面 / ワイド・デスクトップ
  xl: 1200px,
  // 最大画面
  xxl: 1500px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1499px
  ) !default;
  
 