
/* 製品のキービジュアル */
.products-key-visial {
    position: relative;
    padding-bottom: 30px;
    picture.img-fluid > img {
        max-width:1500px;
        width: 100%;
    };
}
/* 製品のキービジュアル(ボタン付き) */
.products-keyvisial-Button {
    position: relative;
    /*padding-bottom: 30px;*/
    picture.img-fluid > img {
        max-width:1500px;
        width: 100%;
    };
}
.products-keyvisial-Button::after{
    @include media-breakpoint-down(lg) {
    content: "";
    display: block;
    clear: both;
    };
}  
/* キービジュアル(背景画像無し) */
.products-NEW-keyvisial {
    position: relative;
    max-width:1500px;
    height: 40vh;
    width: 100vw;
    /*padding-bottom: 30px;*/
    picture.img-fluid > img {
        max-width:750px;
    };
    @include media-breakpoint-down(md) {
        height: auto;
        };
}
.catalog-DL_button{
    background-color: #902FBD;
    color: white;    
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #902FBD;
    @include media-breakpoint-down(xm) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    }
}
.white-transparent-bg{
    position: absolute;
    background:rgba(255,255,255,0.7);
    border: 1px solid #aaa;
    @include media-breakpoint-down(xs) {
        top: 8vw;
        left: 10%;
        width: 80%;
        padding-top: 5vw;
        padding-bottom: 5vw;
    }
    @include media-breakpoint-only(sm) {
        top: 10.5vw;
        left: 25%;
        width: 50%;
        padding-top: 5vw;
        padding-bottom: 5vw;
    };
    @include media-breakpoint-only(md) {
        top: 10.5vw;
        left: 21.6vw;
        width: 30vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    };
    @include media-breakpoint-between(lg, xl) {
        top: 10.5vw;
        left: 21.6vw;
        width: 24.6vw;
        padding-top: 5vw;
        padding-bottom: 5vw;
    };
    @include media-breakpoint-up(xxl) {
        top: 158px;
        left: 328px;
        width: 374px;
        padding-top: 70px;
        height: 280px;
    };
}
#kenpin-title{
    padding-bottom: 60px;
    .white-transparent-bg{
        @include media-breakpoint-down(xl) {
            padding-top: 3vw;
        };
        @include media-breakpoint-up(xxl) {
            padding-top: 50px;
        };
    }
}
#zaiko-title{
    padding-bottom: 80px;
    .white-transparent-bg{
        @include media-breakpoint-down(xl) {
            padding-top: 3vw;
            padding-bottom: 3vw;
        };
        @include media-breakpoint-up(xxl) {
            padding-top: 30px;
        };
    }
}
.products-key-visial h1, .products-keyvisial-Button h1{
    font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:#222222;
    @include media-breakpoint-down(md) {
        font-size: 6vw;
        line-height: 1.35;
        text-align: center;
    }
    @include media-breakpoint-only(sm) {
        font-size: 4vw;
        line-height: 1.35;
        text-align: center;
    }
    @include media-breakpoint-between(md, xl) {
        font-size: 2.38vw;
        line-height: 1.35;
        text-align: center;
    };
    @include media-breakpoint-up(xxl) {
        font-size: 35px;
        line-height: 1.35;
        padding-bottom: 10px;
        text-align: center;
    };
}
.products-key-visial hr.border1, .products-keyvisial-Button hr.border1{
    color:black;
    border-top: none;
    border-bottom: solid 2px #6f706e;
    margin: 0 auto;
    @include media-breakpoint-down(xs) {
        width: 80%;
    }
    @include media-breakpoint-only(sm) {
        width: 25vw;
    }
    @include media-breakpoint-between(md, xl) {
        width: 18.6vw;
    };
    @include media-breakpoint-up(xxl) {
        width: 280px;
    };
}
.products-key-visial hr.border2, .products-keyvisial-Button hr.border2{
    padding-top: 2px;
    color:black;
    border-top: none;
    border-bottom: solid 1px #858585;   
    margin: 0 auto;
    @include media-breakpoint-down(xs) {
        width: 80%;
    }
    @include media-breakpoint-only(sm) {
        width: 25vw;
    }
    @include media-breakpoint-between(md, xl) {
        width: 18.6vw;
    };
    @include media-breakpoint-up(xxl) {
        width: 280px;
    };
}
.products-key-visial h2,.products-keyvisial-Button h2{
    font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:#606060;
    line-height: 1.5;
    @include media-breakpoint-down(xs) {
        padding-top: 2vw;
        font-size: 10px;
        text-align: center;
    }
    @include media-breakpoint-only(sm) {
        padding-top: 2vw;
        font-size: 0.6rem;
        text-align: center;
    }
    @include media-breakpoint-between(md, xl) {
        padding-top: 2vw;
        font-size: 0.92vw;
        text-align: center;
    };
    @include media-breakpoint-up(xxl) {
        padding-top: 30px;
        font-size: 14px;
        text-align: center;
    };
}
/* 開発実績一覧 */
.products-list-bg{
    padding-top:100px;
    background-color:#f5f5f5;
}
#pindex-title{
     position: relative;
     padding-top: 1.5rem;
     padding-bottom: 0;
     text-align: center;
}
#pindex-title2{
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.45;
    color:#1950A8;
    @include media-breakpoint-down(sm) {
        font-size: 3vw;
    };
}
/* 導入実績タイトルの枠 */
.j-title{
    display: flex;
    justify-content: center;
}
/* 導入実績のタイトル */
#package{
    border: 1px solid;
    border-radius: 5px;
    border-color: #afafaf;
    background-color: white;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    text-align: center;
    font-size: 1.7rem;
    /* col-md 991px以下 */
    @media screen and (max-width: 991px){
        width: 95vw;
    }
    /* col-md 5～1000px  */
    @media screen and (max-width: 1000px){
        margin-left: 1.5vw;
        margin-right: 1.5vw;
        width: 970px; 
    }
    /* col-md 1489px以上 */
    @media screen and (min-width: 1489px){
        width: 1450px;
    }
    /* col-md 1001～1488px */
    @media screen and (min-width: 1001px) and (max-width: 1488px){
        width: 970px;
    }
}
.products-list > div.products-item{
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 5px;
    max-width:480px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.products-list > div.products-item > .products-tags{
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
}
.products-tags > .badge-primary{
    background-color: #1950A8;
}
.bg-yellow{
    background-color:#fff7af;
}
.bg-green{
    background-color:#e5f6c9;
}
.bg-light-green{
    background-color:#edfce7;
}
.bg-dark-green{
    background-color: #8BBF7A;
}
.step-white-arrow{
    background:linear-gradient(to bottom left, white 50%, transparent 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, white 50%, transparent 50%) top right / 50% 100% no-repeat;
}
.icon-green{
    color: #009094;
}

/* パッケージ汎用タイトル */
.product-caption{
    background-image:
     repeating-linear-gradient(-45deg,#ffffff, #ffffff 5px,transparent 0, transparent 14px);
    border: 5px solid #ffffff;
    text-align: center;
    font-weight: bold;
    @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
    };
}
.hatyu-nayami .nayami-item{
    border: 5px solid #ffcb49;
}
.hatyu-nayami h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #F38015;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
/* 受注悩み */
.jutyu-nayami .nayami-item{
    border: 5px solid #8cbf7a;
}
.jutyu-nayami h2, .hatyu-nayami h2{
    position: relative;
    text-align: center;
    font-weight: bold;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
    };
}
.jutyu-nayami h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #239100;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
/* EOSご利用シーンの表題 */
.eos_Usescene h2,.eos_Usescene h3{
    text-align: center;
    font-weight: bold;
    color: #032962;
}
/* EOSご利用シーン */
.eos_scene{
    border-radius: 0;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid #032962;
    @include media-breakpoint-down(sm) {
        width:100%;
    };
    @include media-breakpoint-up(md) {
        width: 300px;
    };
    p{
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        font-size:1.7em;
        color: #031D62;
        font-weight: bold;
        line-height: 1.5em;
        @include media-breakpoint-down(md) {
            font-size:20px;
        };
    }
}
/* EOSご利用シーンの普及率 */
.eos_adoption{
    padding: 1rem 0.5rem 2rem 0.5rem;
    font-size: 1.5rem;
}

/* お悩みシーン */
.nayami-item{
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    background-color: #ffffff;
    text-align: center;
    @include media-breakpoint-down(sm) {
        width:100%;
    };
    @include media-breakpoint-up(md) {
        width: 300px;
    };
    h3{
        font-size: 0.9rem;
        .small{
            font-size: 0.8rem;
        };
    };
    p{
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        font-size:1.7em;
        color: #031D62;
        font-weight: bold;
        line-height: 1.5em;
        @include media-breakpoint-down(md) {
            font-size:20px;
        };
    }
}
.nayami-item:not(:nth-child(3)), .eos_scene:not(:nth-child(3))  {
    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    };
    @include media-breakpoint-up(md) {
        margin-right: 20px;
    };
}
.nayami-item:nth-child(3), .eos_scene:nth-child(3)  {
    margin-right: 0;
}
.nayami-item h3{
    padding-top: 10px;
    font-weight: 600;
}
.nayami-item h4{
    font-weight: 600;
}
.nayami-item>div{
    border-top: 1px solid black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
    color: #031D62;
    text-align: left;
    font-weight: bold;
}
.eos_scene>div{
    padding-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
    color: #031D62;
}
/*よくあるご質問*/
.com_question h2{
    font-weight: 600;
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: center;
}
.com_question h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #239100;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
.products_question{
    border: 1px solid #636363;
    padding: 0.5rem 0 0.5rem 0;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background-color: white;
    }
.products_question > div{
    margin: 0 1.5rem 0 1.5rem;
    padding: 0.5rem 0 0.5rem 0;
}
.question_border{
    border-bottom: 1px solid #636363;
}
/* 悩み解決ボタン */
.detail_button{
    border: 1px solid #aaa;
    padding: 5px 15px 5px 15px;
    font-weight: bold;
    background-color:#fff7af;
    color: black;
    font-size: 13px;
    display: block;
    text-align: center;
}
.detail_button span{
    position: relative;
    padding-left: 2rem;
}
.detail_button span::before{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    content: '';
    display: inline-block;
    background-image: url('/img/button_arrow.png');
    background-size: contain;
    height: 16px;
    width: 16px;
}
/* お悩みメニュー */
.nayami_menu{
    display: inline-block;
    padding: 20px 10px 40px 10px;
    position: relative;
    @include media-breakpoint-down(sm){
        border-bottom: 2px solid white;
    }
}
.nayami_menu a{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    
    @extend %fa-icon;
    @extend .fas;
    
    &:after{
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
        color: black;
        content: fa-content($fa-var-angle-double-down);
    };
    &:hover{
        position: absolute;
        opacity: 0.1;
        background-color: #000000;
    };
}
/* 悩み解決リスト */
.success-list{
    margin: 0;
    padding: 0;
    list-style-position: inside;
}
.success-list li{
    padding-left: 2rem;
    padding-bottom: 1rem;
    text-indent: -2rem;
    display: list-item;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 1.5rem;

    @extend %fa-icon;
    @extend .fas;
    
    &:before{
        color: #009094;
        content: fa-content($fa-var-check-circle);
    };
}
/* 受注の流れ */
.jutyu-flow .h1,.hatyu-flow .h1{
    font-weight: 600;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
}
.jutyu-flow .h1::after,.hatyu-flow .h1::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #5b8bfa;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
.jutyu-flow .nav-pills .nav-link.active, .jutyu-flow .nav-pills .show > .nav-link{
    background-color: #fff7af;
    color: black;
}
.jutyu-flow a{
    color: black;
    border: 1px solid #ffcb49;
    display: block;
    margin-bottom: 10px;
    min-width: 200px;
}
.hatyu-flow .nav-pills .nav-link.active, .hatyu-flow .nav-pills .show > .nav-link{
    background-color: #E5F6C9;
    color: black;
}
.hatyu-flow a{
    color: black;
    border: 1px solid #8bbf7a;
    display: block;
    margin-bottom: 10px;
    min-width: 200px;
}
.hatyu-flow span{
    @include media-breakpoint-down(xs){
        font-size: 13px;
    }
    @include media-breakpoint-only(sm){
        font-size: 15px;
    }
    @include media-breakpoint-between(lg, xl) {
        font-size: 2vw;        
    };
    @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;        
    };
}

/* 発注の流れ */
.bg-light-blue{
    background-color:#15B7E5;
}
.bg-favorite{
    background-color: #edb918;
}
.bg-rireki{
    background-color: #7200da;
}
.bg-cart{
    background-color: #83CC66;
}
.bg-confirm{
    background-color: #5fa844;
}
.bg-finish{
    background-color: #4b9432;
}
.step-light-blue-arrow{
    background:linear-gradient(to bottom left, #15B7E5 50%, #83CC66 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, #15B7E5 50%, #83CC66 50%) top right / 50% 100% no-repeat;
}
.step-favorite-arrow{
    background:linear-gradient(to bottom left, #edb918 50%, #83CC66 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, #edb918 50%, #83CC66 50%) top right / 50% 100% no-repeat;
}
.step-rireki-arrow{
    background:linear-gradient(to bottom left, #7200da 50%, #83CC66 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, #7200da 50%, #83CC66 50%) top right / 50% 100% no-repeat;
}
.step-cart-arrow{
    background:linear-gradient(to bottom left, #83CC66 50%, #5fa844 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, #83CC66 50%, #5fa844 50%) top right / 50% 100% no-repeat;
}
.step-confirm-arrow{
    background:linear-gradient(to bottom left, #5fa844 50%, #4b9432 50%) top left/ 50% 100% no-repeat,
    linear-gradient(to bottom right, #5fa844 50%, #4b9432 50%) top right / 50% 100% no-repeat;
}
/* 製品イメージ */
/* 下線表題 */
.product-image h3, .nayami_caption,.hatyu-flow h2,.jutyu-flow h2,.news-list h1{
    font-weight: 600;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #5b8bfa;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
.pos_scene_caption{
    font-weight: 600;
    padding-top: 5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;
    font-size:1.5rem;
    @include media-breakpoint-down(sm) {
        margin-left: 10px;
    };
    &:after{
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #fdcc48;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
/* オレンジ背景表題 */
.product-image h2{
    background-color: #fff7ec;
    border-bottom: 1px solid #fe9e52;
    color: black;
    font-size: 1.5rem;
    font-weight:bold; 
    text-align: center;
    padding: 0.8rem;
}
.product-image .orange-marker{
    color: #ec5a11;
    background:linear-gradient(transparent 50%, #ffe891 50%);
}

.demo-movie{
    position: relative;
    padding-top: 1rem;
    padding-bottom: 0.5em;
    margin-bottom: 1.5rem;
    background-color: #e5f6c9;
    border: 1px solid;
    border-radius: 10px;
    border-color: #e5f6c9;
    width: 500px;
    @include media-breakpoint-down(sm) {
        width:95%;
    };
}
.demo-movie::before{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #e5f6c9;
}
/* 蔵人 */
.kenpin_keyphrase{
    font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    position: relative;
    color:#ffffff;
    background-color:#4A628A;
    @include media-breakpoint-up(xxl) {
        top: 5%;
    };
    @include media-breakpoint-only(xl) {
        top: 5%;
    };
    @include media-breakpoint-only(lg) {
        top: 8%;
    };
    @include media-breakpoint-only(md) {
        top: 8%;
    };
    @include media-breakpoint-only(sm) {
        top: 5%;
    };
    @include media-breakpoint-only(xs) {
        top: 5%;
    };
}
.kenpin_keyphrase h1{
    font-weight: bold;
    color:#f1f1f1;
    position: relative;
    text-align: center;    
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 2.5em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.5em;
    };
    @include media-breakpoint-only(lg) {
        font-size: 3.2vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.2vw;
    };
    @include media-breakpoint-down(sm) {
        font-size: 6.0vw;
    };
}
.kenpin_keyphrase h2{
    font-weight: bold;
    color:#eeeeee;
    position: relative;
    line-height: 1.5;
    @include media-breakpoint-up(xxl) {
        padding-top: 30px;
        font-size: 19px;        
    };
    @include media-breakpoint-only(xl) {
        padding-top: 2vw;
        font-size: 1.3vw;        
    };
    @include media-breakpoint-only(lg) {
        padding-top: 1.8vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(md) {
        padding-top: 2vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(sm) {
        padding-top: 2vw;
        font-size: 2.8vw;        
    };
    @include media-breakpoint-only(xs) {
        padding-top: 2vw;
        font-size: 3vw;        
    };
}
.kenpin_catalog-DL_button{
    background-color: #86B6F6;
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #86B6F6;
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    }
    @include media-breakpoint-down(xs) {
        padding: 10px 0 10px 0;
        font-size: 5vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    }
}
.kenpin_underline{
    position: relative;
    padding-bottom: 40px;
    text-align: center;
    &::after{
        background-color: #4A628A;
        bottom: 20px;
        content: "";
        height: 3px;
        left: 50%;
        line-height: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 40px;
    }
}
.kenpin-Left_underline{
    position: relative;
    padding-bottom: 25px;
    &:after{
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background-color: #4A628A;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
/*蔵人 特徴セクション*/
.kenpin_swiper{
    .swiper-slide>div{
        border: 1px solid #dee2e6;
        border-radius: 1rem;
        height: 380px;
        padding: 5px;
    }    
    @include media-breakpoint-up(md) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .swiper-slide>div{
          width: 250px;
          margin: 0 auto;
        }
    }
    span{
        font-size: 1.35rem;
        @include media-breakpoint-only(md) {
            font-size: 2.2vw;
        }
    }
        /*
        .swiper-wrapper {
          display: flex;
          gap: 24px;
        }
        .swiper-slide {
          width: calc(25% - 18px);
        }
        */
    .swiper_container{
        position: relative;
    }
    .swiper-pagination {
        position: relative !important;
        margin-top: 20px !important;
    }
    .swiper-pagination-bullet {
        height: 15px !important;;
        width: 15px !important;;
      }
}
/*導入後の業務効率化*/
.kenpin-effect{
    p{
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 5px;
        @include media-breakpoint-down(sm) {
            font-size: 3.0vw;
        }
    }
    h5{
        display: inline;
        background: linear-gradient(
            to bottom, /* 方向 */
            transparent 0 60%,  /* 上半分の領域 */
            #b3cff3 40% 100% /* 下半分の領域 */
          );
        @include media-breakpoint-down(sm) {
            font-size: 3.7vw;
        }
    }
    div{
        @include media-breakpoint-down(sm) {
            font-size: 2.5vw;
        }
    }
}
/*機能一覧*/
.kenpin-Kinou{
    display: flex;
    justify-content: center;
    align-items:stretch;
    flex-wrap: nowrap;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
}
.kenpin-Kinou div{
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #032962;
    width: 300px; 
    margin-right: 10px;
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        width: 45%;  
    }
    p{
        height: 40px;
        color:#031D62;
        font-size: 1.1rem;
        margin-bottom: 5px;
    }
}
.kenpin-Kinou div:last-child {
    @include media-breakpoint-up(md) {
        margin-right: 0px;
    }    
}
/*動画タイトル吹き出し*/
.kenpin-demo{
    position: relative;
    padding-top: 1rem;
    padding-bottom: 0.5em;
    margin-bottom: 1.5rem;
    background-color: #b3cff3;
    border: 1px solid;
    border-radius: 10px;
    border-color: #b3cff3;
    width: 500px;
    @include media-breakpoint-down(sm) {
        width:95%;
    };
}
.kenpin-demo::before{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #b3cff3;
}
h4.kenpin_usedcaption {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    border: 3px solid #86B6F6;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    background-color: white;
}
.kenpin_usedtable{
    border-top: none;
    border-bottom: 3px solid #86B6F6;
    border-left: 3px solid #86B6F6;
    border-right: 3px solid #86B6F6;
    background-color: white;
    .used-row{
        >div:not(:last-child) {
            @include media-breakpoint-up(sm) {
                border-right: 3px solid #86B6F6;    
            };
            @include media-breakpoint-down(xs) {
                border-bottom: 3px solid #86B6F6;    
            };
        };
        &:not(:last-child) {
            border-bottom: 3px solid #86B6F6;   
        }
    }
}
.kenpin_usedtable div{
    font-size: 1.5vh;
    @include media-breakpoint-only(sm) {
        font-size: 1.0vh;
    };
}
/*導入前後の比較 工程管理と棚卸お試しで使用*/
.system-before,.system-after{
    @include media-breakpoint-down(xs) {
        width:100%;
        text-align: center;
    };
    @include media-breakpoint-up(sm) {
        width:40%;
    };
}
.system-introduction{
    @include media-breakpoint-up(sm) {
        width:20%;
    };
    position: relative;
    @extend %fa-icon;
    @extend .fas;
    @include media-breakpoint-down(xs) {
        width:100%;
        &:before{
            position: absolute;
            color: #f95d12;
            left: 45%;
            top: 5%;
            font-size: 3rem;
            content: fa-content($fa-var-caret-down);
        }
    };
}
.kenpin-nayami,.zaiko-nayami{
    /*
    background-color: #4d7cb4;
    */
    background-color: #4A78B0;
    text-align: center;
    padding: 20px 0 20px 0;
    font-size: 1.1rem;
    border-radius: 10px;
    position: relative;
    &:before{
        /* 下矢印 */
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -30px;
        border: 30px solid transparent;
        border-top: 25px solid #4A78B0;
    };
    h4{
        color: white;
        @include media-breakpoint-down(xs) {
            font-size: 1.1rem;
        };
        @include media-breakpoint-only(sm) {
            font-size: 1.2rem;
        };
    };
}
.w-830{
    .figure{
        img{
            min-width: 276px;
        }
    }
}
.pos-nayami{
    border: 8px solid #4d7cb4;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    &:before{
        /* 下矢印 */
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -30px;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-top: 25px solid #4d7cb4;
    };
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 0.9rem;
        li{
            @extend %fa-icon;
            @extend .fas;
            position: relative;
            padding-left: 10px;
            margin-left: 10px;
            display: list-item;
            font-weight: normal;
            line-height: 1.25rem;
            &:not(:last-child) {
                padding-bottom: 15px;
            };
            &:before{
                position: absolute;
                content: fa-content($fa-var-exclamation-triangle);
                color: #ffd765;
                font-weight: 900;
                left: -10px;
                text-shadow: #000 1px 1px 0, #000 -1px -1px 0, #000 -1px 1px 0, #000 1px -1px 0, #000 0px 1px 0, #000 0 -1px 0, #000 -1px 0 0, #000000 1px 0 0;
            }
        };
    };
}
.pos-kaiketsu{
    div{
        border: 8px solid #fecd45;
        padding: 20px;
        border-radius: 8px;
    };
    h3{
        color: #4d7cb4;
        font-weight: bold;
        border-bottom: solid #999798;
        padding-top: 4rem;
        padding-bottom:1.5rem;
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(xs) {
            font-size: 1.1rem;
        };
        @include media-breakpoint-up(ms) {
            font-size: 1.4rem;
        };
    };
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 0.9rem;
        li{
            @extend %fa-icon;
            @extend .fas;
            position: relative;
            padding-left: 10px;
            margin-left: 10px;
            display: list-item;
            font-weight: normal;
            line-height: 1.25rem;
            &:not(:last-child) {
                padding-bottom: 15px;
            };
            &:before{
                position: absolute;
                color: #009094;
                content: fa-content($fa-var-check-circle);
                font-weight: 900;
                left: -10px;
            }
        };
    };
}
.kaiketsu-item{
    font-size: 1.0rem;
    @include media-breakpoint-down(sm) {
        font-size: 0.8rem;
    };
}
.nayami-detail{
    margin-top: 10px;
    background-color: white;
    color: black;
    text-align: start;
    font-size: 0.8rem;
    height: 80%;
    line-height: 1.5rem;
    border-radius: 5px;
    border: solid 2px #96a4fd;
}
.nayami-detail ul{
    list-style: none;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 10px;
    margin: 0;
}
.nayami-detail ul li{
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
}
.nayami-detail ul li:before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\F071"; /* アイコンのunicode */
    color: #ffd765;
    font-weight: 900;
    left: -10px;
    text-shadow: #000 1px 1px 0, #000 -1px -1px 0, #000 -1px 1px 0, #000 1px -1px 0, #000 0px 1px 0, #000 0 -1px 0, #000 -1px 0 0, #000 1px 0 0;
}
.kaiketsu{
    text-align: center;
    color: #4d7cb4;
    font-weight: bold;
    line-height: 2.5rem;
    border-bottom: solid #999798;
    margin: 0 auto;
}
.kaiketsu h3{
    @include media-breakpoint-down(xs) {
        font-size: 1.2rem;
    };
    @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
    };
}
.kaiketsu-item{
    border: 5px solid #ffcb49;
    border-radius: 10px;
    background-color: #ffffff;
    width: 100%;
    height: 70px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-fluid > div.answer > div.col-sm-6{
    padding: 0;
}
.kai-detail{
    font-size: 0.75rem;
    align-items: center;
}
/* 機能一覧 */
.function-list{
    background-color: #eee;
}
.function-list h2{
    font-weight: 600;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
}
.function-list h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #5b8bfa;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
.function-list h3{
    font-size: 1.25rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #a9a9a9;
}
.function-list .function-item{
    background-color: white;
    min-height: 200px;
}
.half{
    @include media-breakpoint-up(sm) {
        width: 50%;
    }
    @include media-breakpoint-down(xs) {
        width: 100%;
    }
}
/* お問い合わせ */
.product-contact{
    @include media-breakpoint-up(sm) {
        background-size: cover;
        padding-top: 5rem;
        padding-bottom: 5rem;
        background: linear-gradient(180deg,rgba(221, 136, 255, 0.6), rgba(221,136,255, 0.6))fixed, url('/img/contact/contact.jpg') center bottom no-repeat;
    };
    @include media-breakpoint-down(xs) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background:rgba(221, 136, 255, 0.6);
    };
}
.product-contact-title{
    font-size: 3rem;
    color: white;
    font-weight: 600;
    font-style: italic;
}
.product-contact-btn{
    border: 2px solid white;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /*
    background-color: #ec77d0;
    */
    background-color: #E85EC8;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
/* 使用機器表題 */
h4.used-caption {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    border: 3px solid #8cbf7a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    background-color: white;
}
.used-table{
    border-top: none;
    border-bottom: 3px solid #8cbf7a;
    border-left: 3px solid #8cbf7a;
    border-right: 3px solid #8cbf7a;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    .used-row{
        >div:not(:last-child) {
            @include media-breakpoint-up(sm) {
                border-right: 3px solid #8cbf7a;    
            };
            @include media-breakpoint-down(xs) {
                border-bottom: 3px solid #8cbf7a;    
            };
        };
        &:not(:last-child) {
            border-bottom: 3px solid #8cbf7a;   
        }
    }
}
.green-border{
    border-color: #8cbf7a;
}
.yellow-marker{
    background:linear-gradient(transparent 50%, #fff799 50%);
}
/* IT導入補助金 */
.darkpink-bg{
    background-color: rgba(255,227,250,0.88);
    color: #e65fab;
}
.darkpink a{
    color: #d44797;
}
/* システムイメージ */
.system-image h2{
    font-weight: 600;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
}
.system-image h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #5b8bfa;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
/* システムフローチャート用 */
.bg-blue{
    background-color: #f0f5ff;
}
.system-flowchart .zimusyo{
    color: #376092;
    font-size:1.4rem;
    @include media-breakpoint-down(md) {
        font-size:1.2rem;
    }
    @include media-breakpoint-down(sm) {
        font-size:1rem;
    }
}
.system-flowchart .soko{
    color: #558ED5;
    font-size:1.4rem;
    @include media-breakpoint-down(md) {
        font-size:1.2rem;
    }
    @include media-breakpoint-down(sm) {
        font-size:1rem;
    }
}
.system-flowchart h2{
    font-weight: 600;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    text-align: center;
}
.system-flowchart h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #376092;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
.system-flowchart .flow_any h2::after{
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #f0f5ff;
    color: black;
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
}
.system-flowchart .flow_icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #558ED5;
    color: white;
    font-size: 25px;
    text-align:center;
    line-height: 50px;
    float:left;
}
.system-flowchart .flow_icon2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #376092;
    color: white;
    font-size: 25px;
    text-align:center;
    line-height: 50px;
    float:left;
}
.system-flowchart .flow_any .flow_icon{
    background: #558ED5;
    color: white;
}
.system-flowchart .flow_icon2{
    background: #376092;
    color: white;
}
.system-flowchart .flow_icon, .system-flowchart .flow_icon2{
    @include media-breakpoint-up(md) {
        margin-top: 15px;
        margin-right: 15px;
    };    
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        margin-right: 10px;
    }
}
.system-flowchart .flow_any .flow_title{
    @include media-breakpoint-up(md) {
        position: relative;
        width: 150px;
        min-height: 80px;
        height: 100%;
        max-height: 150px;
        background: #558ED5;
        text-align: center;
        vertical-align: middle;
        float:left;
        color: white;
        margin-right: 15px;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        color: #558ED5; /* 薄すぎて見えないため他のフローチャートと同じ色とする */
        font-size:1.125rem;
        font-weight:300;
        height: 70px;
        z-index: 0;
    }
}
.system-flowchart .flow_any .flow_title2{
    @include media-breakpoint-up(md) {
        position: relative;
        width: 150px;
        min-height: 80px;
        height: 100%;
        max-height: 150px;
        background: #376092;
        text-align: center;
        vertical-align: middle;
        float:left;
        color: white;
        margin-right: 15px;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        color: #376092; /* 薄すぎて見えないため他のフローチャートと同じ色とする */
        font-size:1.125rem;
        font-weight:300;
        height: 70px;
        z-index: 0;
    }
}
.system-flowchart .flow_title{
    @include media-breakpoint-up(md) {
        position: relative;
        width: 150px;
        min-height: 80px;
        height: 100%;
        max-height: 150px;
        background: #558ED5;
        text-align: center;
        vertical-align: middle;
        float:left;
        color: white;
        margin-right: 15px;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        color: #558ED5;
        font-size:1.125rem;
        font-weight:300;
        height: 70px;
        z-index: 0;
    }
}
.system-flowchart  .flow_title2{
    @include media-breakpoint-up(md) {
        position: relative;
        width: 150px;
        min-height: 80px;
        height: 100%;
        max-height: 150px;
        background: #376092;
        text-align: center;
        vertical-align: middle;
        float:left;
        color: white;
        margin-right: 15px;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        color: #376092;
        font-size:1.125rem;
        font-weight:300;
        height: 70px;
        z-index: 0;
    }
}
.system-flowchart .flow_title::after{
    @include media-breakpoint-up(md) {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -9px;
        left: 0;
        border-style: solid;
        border-color: #558ED5 transparent transparent transparent;
        border-width: 10px 75px 0 75px;
        z-index: 1;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        margin-bottom: 50px;
    }
}
.system-flowchart .flow_title2::after{
    @include media-breakpoint-up(md) {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -9px;
        left: 0;
        border-style: solid;
        border-color: #376092 transparent transparent transparent;
        border-width: 10px 75px 0 75px;
        z-index: 1;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        margin-bottom: 50px;
    }
}
.system-flowchart {
    .flow_any .flow_title,.flow_any .flow_title2{
        &:after{
            @include media-breakpoint-up(md) {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                bottom: -9px;
                left: 0;
                border-style: solid;
                border-color: #f0f5ff transparent transparent transparent;
                border-width: 10px 75px 0 75px;
                z-index: 1;
            };
            @include media-breakpoint-down(sm) {
                position: relative;
                margin-bottom: 50px;
            }
        }
        &:last-child {
            &:after{
            border: none;
            }
        }
    }
}
.flow_item:last-child>.flow_title::after{
    @include media-breakpoint-between(sm, xl) {
        display: none;
    };
}
.flow_item:last-child>.flow_title2::after{
    @include media-breakpoint-between(sm, xl) {
        display: none;
    };
}
.flow_title span, .flow_title2 span {
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform : translate(-50%,-50%);
        transform : translate(-50%,-50%);
        width: 150px;
        text-align: center;/*一応BOX内の文字も中央寄せ*/
    };
    @include media-breakpoint-down(sm) {
        position: absolute;
        top: 50%;
        z-index: 0;
        transform: translate(0, -50%);
    }
}
.flow_text{
    @include media-breakpoint-up(md) {
        float: left;                /* 要素を右に回り込ませる */
        width: calc(100% - 300px);  /* 文字の箱の大きさ調整 */
    };
    @include media-breakpoint-down(sm) {
        float: left;
    };
}
.system-flowchart .flow_item{
    @include media-breakpoint-up(md) {
        margin-bottom:10px;
    };
    @include media-breakpoint-down(sm) {
        position: relative;
        margin-bottom: 50px;
    };
}
.system-flowchart .flow_any .flow_item::after{
    @include media-breakpoint-down(sm) {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -45px;
        left: calc(50% - 75px);
        border-style: solid;
        border-color: #f0f5ff transparent transparent transparent;
        border-width: 20px 75px 0 75px;
        z-index: 1;
    };
}
.system-flowchart .flow_item::after{
    @include media-breakpoint-down(sm) {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        bottom: -45px;
        left: calc(50% - 75px);
        border-style: solid;
        border-color: #558ED5 transparent transparent transparent;
        border-width: 20px 75px 0 75px;
        z-index: 1;
    };
}
.system-flowchart .flow_item:last-child::after{
    @include media-breakpoint-down(sm) {
        display: none;
    };
};
/* 受託開発のページ */
.delelopment-list-bg{
    padding-top:100px;
    background-color:#f5f5f5;
    @include media-breakpoint-down(md) {
        padding-top:20px;
    };
}

/*EOSページ*/
.w-970{
    /* 3連ボタンと同じ幅 */
    width: 970px;
    margin: 0 auto;
    @media screen and (max-width: 999px) {
        width: 100%;
    }
}
.eos_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xl) {
        top: 33%;
        right: 15%;
    };
    @include media-breakpoint-only(lg) {
        top: 33%;
        right: 13%;
    };
    @include media-breakpoint-only(md) {
        top: 20%;
        right: 5%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 5%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 5%;
    };
}
.eos_keyphrase h1{
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    /*color:white;
    line-height: 150%;
    text-shadow: 0px 1px 5px black;*/
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 4vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 4vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 4vw;
    };
}
/*資料ダウンロードボタン*/
.eos_download_button{
    background-color: #4FB614;
    color: white;    
    text-shadow: 0px 1px 2px #000;
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #4FB614;
    @include media-breakpoint-up(xxl) {
        /* 1500px以上*/
        padding: 10px 30px 10px 30px;
        font-size: 20px;
        display: flex;
        position: absolute;
        bottom: 20%;
        right: 28%;
    };
    @include media-breakpoint-only(xl) {
        padding: 10px 30px 10px 30px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 15%;
        right: 30%;
    };
    @include media-breakpoint-only(lg) {
        padding: 10px 20px 10px 20px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 10%;
        right: 27%;
    };
    @include media-breakpoint-only(md) {
        padding: 10px 0 10px 0;
        font-size: 2vw;
        width: 50%;
        float: left;
        text-align: center;
        border: 3px solid #ffffff;
    };
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 2.5vw;
        width: 50%;
        float: left;
        text-align: center;
        border: 3px solid #ffffff;
    };
    @include media-breakpoint-only(xs) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    };
}

.catalog_download_button{
    background-color: #902FBD;
    color: white;    
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #ffffff;
    @include media-breakpoint-up(xxl) {
        /* 1500px以上*/
        padding: 10px 30px 10px 30px;
        font-size: 20px;
        display: flex;
        position: absolute;
        bottom: 20%;
        right: 29%;
    };
    @include media-breakpoint-only(xl) {
        padding: 10px 30px 10px 30px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 15%;
        right: 30%;
    };
    @include media-breakpoint-only(lg) {
        padding: 10px 20px 10px 20px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 10%;
        right: 27%;
    };
    @include media-breakpoint-only(md) {
        padding: 10px 0 10px 0;
        font-size: 2vw;
        width: 50%;
        float: left;
        text-align: center;
        border: 3px solid #ffffff;
    };
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 2.5vw;
        width: 50%;
        float: left;
        text-align: center;
        border: 3px solid #ffffff;
    };
    @include media-breakpoint-only(xs) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    };
}

/*よくある質問へのボタン*/
.eos_question_button{
    background-color: #c8e49c;
    color: #4FB614;
    border-radius:8px;
    font-weight: bold;
    text-shadow: -1px -1px 0 white, -1px 0 0 white, -1px 1px 0 white,
                    0 -1px 0 white,                  0 1px 0 white,
                  1px -1px 0 white,  1px 0 0 white,  1px 1px 0 white;
    border: solid 3px #4FB614;
    @include media-breakpoint-up(xxl) {
        /* 1500px以上*/
        padding: 10px 30px 10px 30px;
        font-size: 20px;
        display: flex;
        position: absolute;
        bottom: 20%;
        right: 8%;
    };
    @include media-breakpoint-only(xl) {
        padding: 10px 30px 10px 30px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 15%;
        right: 8%;
    };
    @include media-breakpoint-only(lg) {
        padding: 10px 20px 10px 20px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 10%;
        right: 6%;
    };
    @include media-breakpoint-only(md) {
        padding: 10px 0 10px 0;
        font-size: 2vw;
        width: 50%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 2.5vw;
        width: 50%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(xs) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
    };
}

.question_button{
    background-color: #fff;
    color: #902FBD;
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #902FBD;
    @include media-breakpoint-up(xxl) {
        /* 1500px以上*/
        padding: 10px 30px 10px 30px;
        font-size: 20px;
        display: flex;
        position: absolute;
        bottom: 20%;
        right: 9%;
    };
    @include media-breakpoint-only(xl) {
        padding: 10px 30px 10px 30px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 15%;
        right: 8%;
    };
    @include media-breakpoint-only(lg) {
        padding: 10px 20px 10px 20px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 10%;
        right: 6%;
    };
    @include media-breakpoint-only(md) {
        padding: 10px 0 10px 0;
        font-size: 2vw;
        width: 50%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 2.5vw;
        width: 50%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(xs) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
    };
}
/* 充実のサポート体制の枠 */
.eos-support{
    font-size: 1.2em;
}
.eos-support h2{
    color: #032962;
    font-weight: 600;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #5b8bfa;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
/* どこレジ３号 */
.regi3_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xxl) {
        top: 33%;
        right: 7%;
    };
    @include media-breakpoint-only(xl) {
        top: 33%;
        right: 10%;
    };
    @include media-breakpoint-only(lg) {
        top: 34%;
        right: 7%;
    };
    @include media-breakpoint-only(md) {
        top: 20%;
        right: 2%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 2%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 2%;
    };
}
.regi3_keyphrase h1{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.7vw;
    };
}
.regi3-tsuyomi h3{
    font-weight: 600;
    text-align: center;
    padding-bottom: 1rem;
}
.regi3-tsuyomi h4,.dokoden-tsuyomi h4{
    font-weight: 600;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #5b8bfa;
        position: absolute;
        bottom: 0;
    };
}
.regi3-title{
    font-size: 1.5rem;
    font-weight:bold; 
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background-color: #5b8bfa;
        position: absolute;
        bottom: 0;
        left: calc(50% - 25px);
    };
}
.pos{
    font-size: 1.2em;
    h2{
        color: #F38015;
        text-align: center;
        font-weight: 600;
        padding-top: 2.5rem;
        margin-bottom: 1rem;
        @include media-breakpoint-only(xs) {
            padding-top: 1.5rem;
        };
    };
    h3{
        font-weight: 500;  
    };
}
.pos-title{
    font-size: 1.6rem;
    @include media-breakpoint-only(md) {
        padding-left: 7rem;
    };
    @include media-breakpoint-down(sm) {
        padding-left: 1rem;
    };
}
.pos-naiyou{
    font-size: 1.6rem;
    @include media-breakpoint-only(md) {
        font-size: 1.4rem;
        padding-left: 9rem;
    };
    @include media-breakpoint-down(sm) {
        font-size: 1.3rem;
        padding-left: 2.5rem;
    };
}
/* 棚卸 */
.tanaoroshi_keyphrase{
    font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    position: relative;
    background-color:rgb(248,253,255);
    @include media-breakpoint-up(xxl) {
        top: 5%;
    };
    @include media-breakpoint-only(xl) {
        top: 5%;
    };
    @include media-breakpoint-only(lg) {
        top: 8%;
    };
    @include media-breakpoint-only(md) {
        top: 8%;
    };
    @include media-breakpoint-only(sm) {
        top: 5%;
    };
    @include media-breakpoint-only(xs) {
        top: 5%;
    };
}
.tanaoroshi_keyphrase h1{
    font-weight: bold;
    color:#222222;
    position: relative;
    text-align: center;    
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 3em;
    };
    @include media-breakpoint-between(xl,lg) {
        font-size: 3vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.5vw;
    };
    @include media-breakpoint-down(sm) {
        font-size: 6.0vw;
    };
}
.tanaoroshi_keyphrase  h2{
    font-weight: bold;
    color:#606060;
    position: relative;
    line-height: 1.5;
    @include media-breakpoint-up(xxl) {
        padding-top: 30px;
        font-size: 19px;        
    };
    @include media-breakpoint-only(xl) {
        padding-top: 2vw;
        font-size: 1.3vw;        
    };
    @include media-breakpoint-only(lg) {
        padding-top: 2vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(md) {
        padding-top: 2vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(sm) {
        padding-top: 2vw;
        font-size: 2.8vw;        
    };
    @include media-breakpoint-only(xs) {
        padding-top: 2vw;
        font-size: 3vw;        
    };
}
.tanaoroshi_catalog-DL_button{
    background-color: #FFC002;
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #FFC002;
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #000000;
    }
    @include media-breakpoint-down(xs) {
        padding: 10px 0 10px 0;
        font-size: 5vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #000000;
    }
}
/*棚卸 特徴セクション*/
.tanaoroshi_Featuresh2{
    color: #032962;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
}
.tana_swiper{
    .swiper-slide>div{
        border: 1px solid #dee2e6;
        border-radius: 1rem;
        height: 350px;
        padding: 5px;
    }    
    @include media-breakpoint-up(md) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .swiper-slide>div{
          width: 250px;
          margin: 0 auto;
        }
    }
    span{
        font-size: 1.35rem;
        @include media-breakpoint-only(md) {
            font-size: 2.2vw;
        }
    }
        /*
        .swiper-wrapper {
          display: flex;
          gap: 24px;
        }
        .swiper-slide {
          width: calc(25% - 18px);
        }
        */
    .swiper_container{
        position: relative;
    }
    .swiper-pagination {
        position: relative !important;
        margin-top: 20px !important;
    }
    .swiper-pagination-bullet {
        height: 15px !important;;
        width: 15px !important;;
      }
}
/*棚卸 お悩みセクション*/
.tana-nayami_section{
    background: linear-gradient(0deg, #FFF4CE 57%, #fff 0);
    @media screen and (max-width: 1000px){
        background: linear-gradient(0deg, #FFF4CE 60%, #fff 0);
    }
    @media screen and (max-width: 600px){
        background: linear-gradient(0deg, #FFF4CE 63%, #fff 0);
    }
    position: relative;
    h4{
        position: absolute;
        top: 250px;
        left:50%;
        background-color: #FFF4CE;
        border-radius: 20px;
        transform: translateX(-50%);
        width: 600px;
        z-index: 100;
        font-weight: bold;
        @media screen and (max-width: 991px){
            top: 270px;
        }
        @media screen and (max-width: 767px){
            top: 220px;
            width: 80%;
        }
        @media screen and (max-width: 575px){
            top: 210px;
            width: 80%;
        }
        @media screen and (max-width: 362px){
            top: 190px;
            width: 80%;
        }
    }
    .solution_caption {
        text-align: center;
        padding-bottom: 1rem;
        padding-top: 3rem;
        @include media-breakpoint-down(md) {
            padding-top: 5rem;
        }
        span{
            font-size:1.35rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
            line-height: 1.2;
            color: #FF6200;
            background: linear-gradient(transparent 50%, #fffb02 50%);
            @include media-breakpoint-only(md) {
                font-size: 2vw;
            }
        }
    }
}
.tana-fukidasi{
    position: relative;
    width: 100%;
    height: 82px;
    padding: 6px 20px;
    color: #000;
    border-radius: 10px;
    background-color: #f6f6f6;
    @media screen and (max-width: 780px){
        padding: 6px 10px;
    };
    @include media-breakpoint-only(md) {
        height: 104px;
    };
    @include media-breakpoint-down(sm) {
        height: 60px;
    };
}
.tana-fukidasi:before {
    content: "";
    position: absolute;
    top: calc(100% - 1px); /* 1px上にずらす */
    left: 50%;
    z-index: -1;
    width: 20px;
    height: 12px;
    transform: translateX(-50%);
    box-sizing: border-box;
    background-color: #f6f6f6;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    box-sizing: border-box;
}
/*棚卸Youtube*/
.tana-demo{
    position: relative;
    padding-top: 1rem;
    padding-bottom: 0.5em;
    margin-bottom: 1.5rem;
    background-color: #FFF4CE;
    border: 1px solid;
    border-radius: 10px;
    border-color: #FFF4CE;
    width: 500px;
    @include media-breakpoint-down(sm) {
        width:95%;
    };
}
.tana-demo::before{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #FFF4CE;
}
/* 棚卸 最小構成 */
h4.tana_usedcaption {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    border: 3px solid #FFC002;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    background-color: white;
}
.tana_usedtable{
    border-top: none;
    border-bottom: 3px solid #FFC002;
    border-left: 3px solid #FFC002;
    border-right: 3px solid #FFC002;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    .used-row{
        >div:not(:last-child) {
            @include media-breakpoint-up(sm) {
                border-right: 3px solid #FFC002;    
            };
            @include media-breakpoint-down(xs) {
                border-bottom: 3px solid #FFC002;    
            };
        };
        &:not(:last-child) {
            border-bottom: 3px solid #FFC002;   
        }
    }
}

/* 棚卸システムお試しサービス */
.tanaoroshi_trial_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xxl) {
        top: 33%;
        right: 11%;
    };
    @include media-breakpoint-only(xl) {
        top: 33%;
        right: 13%;
    };
    @include media-breakpoint-only(lg) {
        top: 33%;
        right: 11%;
    };
    @include media-breakpoint-only(md) {
        top: 20%;
        right: 5%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 5%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 5%;
    };
}
.tanaoroshi_trial_keyphrase h1{
    position: relative;
    color: rgb(255, 0, 0);
    text-shadow: -3px -3px 0 rgb(253, 253, 253), -3px 0 0 rgb(253, 253, 253), -3px 3px 0 rgb(253, 253, 253),
                    0 -3px 0 rgb(253, 253, 253),                  0 3px 0 rgb(253, 253, 253),
                  3px -3px 0 rgb(253, 253, 253),  3px 0 0 rgb(253, 253, 253),  3px 3px 0 rgb(253, 253, 253);
    line-height: 100%;
    @include media-breakpoint-up(xxl) {
        font-size: 4.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 4vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 4vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.7vw;
    };
}
.tanaoroshi_trial_keyphrase .h2{
    position: relative;
    color: rgb(0, 0, 0);
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 120%;
    font-weight: bold;
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.7vw;
    };
}
/*お試しサービス申し込みフォームへのボタン*/
.application_form_button{
    background-color: #6e97f0;
    color: #000000;
    border-radius:8px;
    font-weight: bold;
    text-shadow: -1px -1px 0 white, -1px 0 0 white, -1px 1px 0 white,
                    0 -1px 0 white,                  0 1px 0 white,
                  1px -1px 0 white,  1px 0 0 white,  1px 1px 0 white;
    border: solid 3px #0073c0;
    @include media-breakpoint-up(xxl) {
        /* 1500px以上*/
        padding: 10px 30px 10px 30px;
        font-size: 20px;
        display: flex;
        position: absolute;
        bottom: 5%;
        right: 15%;
    };
    @include media-breakpoint-only(xl) {
        padding: 10px 30px 10px 30px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 5%;
        right: 15%;
    };
    @include media-breakpoint-only(lg) {
        padding: 10px 20px 10px 20px;
        font-size: 1.5vw;
        display: flex;
        position: absolute;
        bottom: 5%;
        right: 15%;
    };
    @include media-breakpoint-only(md) {
        padding: 10px 0 10px 0;
        font-size: 2vw;
        width: 100%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 2.5vw;
        width: 100%;
        float: left;
        text-align: center;
    };
    @include media-breakpoint-only(xs) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
    };
}
.trial_caption{   
    color: #F38015;
    text-align: center;
    font-weight: 600;
    padding-top: 2rem;
    margin-bottom: 2rem;
    @include media-breakpoint-only(xs) {
        padding-top: 1.5rem;
    };
}
.trial_box{
    background-color:#6e97f0;
    div{
        position: relative;
        @include media-breakpoint-up(xs) {
            top: 20%;
        }
        @include media-breakpoint-down(xs) {
            top: 0%;
        }
        color: #000000;
        font-weight: bold;
        text-shadow: -1px -1px 0 white, -1px 0 0 white, -1px 1px 0 white,
                        0 -1px 0 white,                  0 1px 0 white,
                      1px -1px 0 white,  1px 0 0 white,  1px 1px 0 white;
    }
}
.btn-ultra-big{
    @include media-breakpoint-up(sm) {
        padding: 0.5rem 18rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 0.5rem 10rem;
    }
    @include media-breakpoint-down(xs) {
        padding: 0.5rem 6rem;
    }
}

/* 販売管理サブシステム */
.hanbai_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xxl) {
        top: 20%;
        right: 17%;
    };
    @include media-breakpoint-only(xl) {
        top: 24%;
        right: 16%;
    };
    @include media-breakpoint-only(lg) {
        top: 24%;
        right: 15%;
    };
    @include media-breakpoint-only(md) {
        top: 23%;
        right: 11%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 11%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 11%;
    };
}
.hanbai_keyphrase h1{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    margin-bottom: 0;
    padding-bottom: 0;
    @include media-breakpoint-up(xxl) {
        font-size: 3.3em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 3.2vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 3.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.7vw;
    };
}
.hanbai_keyphrase h2{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    margin: 0;
    padding-bottom: 0;
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.8vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 3.0vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.0vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.0vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.0vw;
    };
}
.hanbai_keyphrase h4{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 2.0em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.1vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.1vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 1.9vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 1.9vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 1.9vw;
    };
}
/* 工程管理サブシステム */
.koteizaiko_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xxl) {
        top: 33%;
        right: 11%;
    };
    @include media-breakpoint-only(xl) {
        top: 33%;
        right: 13%;
    };
    @include media-breakpoint-only(lg) {
        top: 33%;
        right: 11%;
    };
    @include media-breakpoint-only(md) {
        top: 20%;
        right: 4%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 4%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 4%;
    };
}
.koteizaiko_keyphrase h1{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.7vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.7vw;
    };
}
.kotei-mieru{
    color: #0d0e6dd3;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}
.middle_title{
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.bastyle{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #333;
    margin: 0 1vw;
}
.easy-title{
    font-size: 1.5rem;
    color: #F38015;
    text-align: center;
    font-weight: bold;
    position: relative;
}
.w-1300{
    /* 3連ボタンと同じ幅 */
    width: 1300px;
    margin: 0 auto;
    @media screen and (max-width: 1500px) {
        width: 100%;
    }
}
.kotei-jisseki{
    border-radius: 0;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid #032962;
    margin-right: 0;
    
    @include media-breakpoint-down(md) {
        width:700px;
        //margin-bottom: 20px;
        margin: 0 auto 20px auto;
    };
    @include media-breakpoint-down(sm) {
        width:100%;
        margin-bottom: 20px;
    };
    @include media-breakpoint-up(lg) {
        width: 300px;
        margin-right: 20px;
        //margin: 0 auto 20px auto;
    };
    p{
        padding-top:1rem;
        font-size:1.5em;
        color: #031D62;
        font-weight: bold;
        line-height: 1.5em;
        @include media-breakpoint-down(lg) {
            font-size:20px;
        };
    }
    div{
        padding-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        color: #031D62;
    }
}
.kotei-jisseki:last-child{
    @include media-breakpoint-up(lg) {
        margin-right: 0px;
    };
}
.kenpin-jirei>p{
    text-align: center;
    font-weight: bold;
    color: #032962;
    font-size:1.5em;
}
.kenpin-jirei>h2{
    color: #F38015;
    text-align: center;
    font-weight: bold;
}
.kenpin-jisseki{
    border-radius: 0;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid #032962;
    margin-right: 0;
    @include media-breakpoint-down(sm) {
        width:100%;
        margin-bottom: 20px;
    };
    @include media-breakpoint-up(md) {
        width: 300px;
        margin: 0 20px 0 10px;
    };
    p{
        padding-top:1rem;
        font-size:1.5em;
        color: #031D62;
        font-weight: bold;
        line-height: 1.5em;
        @include media-breakpoint-down(md) {
            font-size:20px;
        };
    }
    div{
        padding-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        color: #031D62;
    }
}
.kenpin-jisseki:last-child{
    @include media-breakpoint-up(md) {
        margin-right: 10px;
    };
}
/* どこ伝 */
.dokoden_keyphrase{
    position: absolute;
    text-align: center;    
    @include media-breakpoint-up(xxl) {
        top: 33%;
        right: 11%;
    };
    @include media-breakpoint-only(xl) {
        top: 33%;
        right: 13%;
    };
    @include media-breakpoint-only(lg) {
        top: 34%;
        right: 10%;
    };
    @include media-breakpoint-only(md) {
        top: 20%;
        right: 2%;
    };
    @include media-breakpoint-only(sm) {
        top: 20%;
        right: 2%;
    };
    @include media-breakpoint-only(xs) {
        top: 15%;
        right: 2%;
    };
}
.dokoden_keyphrase h1{
    position: relative;
    color: black;
    text-shadow: -1px -1px 0 rgb(253, 253, 253), -1px 0 0 rgb(253, 253, 253), -1px 1px 0 rgb(253, 253, 253),
                    0 -1px 0 rgb(253, 253, 253),                  0 1px 0 rgb(253, 253, 253),
                  1px -1px 0 rgb(253, 253, 253),  1px 0 0 rgb(253, 253, 253),  1px 1px 0 rgb(253, 253, 253);
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 2.6em;
    };
    @include media-breakpoint-only(xl) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(lg) {
        font-size: 2.5vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.3vw;
    };
    @include media-breakpoint-only(sm) {
        font-size: 3.3vw;
    };
    @include media-breakpoint-only(xs) {
        font-size: 3.3vw;
    };
}
.dokoden-tsuyomi h3{
    color: #F38015;
    text-align: center;
    font-weight: bold;
    padding-bottom: 1rem;
    @include media-breakpoint-only(xs) {
        font-size: 4.5vw;
    };
}
.dokoden-syukei{
    border-radius: 0;
    border: 1px solid #032962;
    padding-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
    color: #031D62;
}
.dokoden-syukei h4{
    font-size:1.5em;
    color: #031D62;
    font-weight: bold;
    line-height: 1.5em;
    @include media-breakpoint-down(md) {
        font-size:20px;
    };
}
/* どこ伝　Youtube表題 */
.product-movie .caption{
    border-left: thick solid #ffcb49;
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 1rem;
}

/* よくある質問　アコーディオン */
.products_question_toggle{
    display: none;
}
.products_question_option{
    position: relative;
    margin-bottom: 1em;
}
.products_question_content{
    background-color: #E0E0E0;
    max-height: 0;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.3s;
    &::before{        
        content: "A";
        color: #000;
        font-weight: 700;
        position: absolute;
        left: 1.25em;
        top: 1em;
        width: 1em;
        height: 1em;
    }
    p{
        margin: 0;
        padding: 1em 1em 1em 3em;
        font-size: 1em;
        line-height: 1.5;
    }
}
.products_question_title{
    background-color: white;
    border: solid 1px #ccc;
    padding: 1em 3em 1em 3em;
    display: block;
    color: #333;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.3s;
    margin: 0;
    &::after{        
        content: "\f055";
        font-family: "Font Awesome 5 Free";
        color: #B00C0C;
        position: absolute;
        right: 2em;
        top: 1em;
        width: 1em;
        height: 1em;
    }
    &::before{        
        content: "Q";
        color: #800000;
        position: absolute;
        left: 1.25em;
        top: 1em;
        width: 1em;
        height: 1em;
    }
}
.products_question_toggle:checked + .products_question_title + .products_question_content {
    max-height: 500px;
    transition: all 1.5s;
}
.products_question_toggle:checked + .products_question_title::after {
    content: "\f057";
    font-family: "Font Awesome 5 Free";
    color: #A3A3A3;
}
/* 関連システムご紹介 */
#related-products-section{
    h3{
        text-align: center;
    }
}
.related-products-item{
    max-width: 455px; 
    @include media-breakpoint-down(xs) {
        border-bottom: 1px dotted #c2c2c2;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
}
.related_thum{
    text-align: center;
    height: auto;
    border: 1px solid #dee2e6;
    img{
        height: auto;
        width: 100%;            
    }
    @include media-breakpoint-down(xs) {
        border: 0;
        height: auto;
        width: 100px;       
        img{
            height: auto; 
            width: 100px;       
            object-fit: cover;    
        }
    };
}
.related_title{
    color: white;
    background-color:#00095E;
    text-align: center;
    height: 50px; 
    line-height:50px;
    padding-right: 0.8em;
    padding-left: 0.8em;
    a{
        color: white;
    }
    @include media-breakpoint-down(xs) {
        color: #00095E;
        font-weight: 700;
        background-color:white;
        height: auto; 
	    line-height: normal;
        display: inline-block;
        font-size: 0.9em;
        a{
            color: #00095E;
        }
    }
    p{
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        margin: 0;
    }
}
.related_detail{
    padding: 1em;
    border: 1px solid #dee2e6;
    p{
        min-height: 90px;
    }
    a{
        display: block;
        text-align: right;
    }
    @include media-breakpoint-down(xs) {
        padding: 0.5em;
        min-height: auto;
        border: 0;
        font-size: 0.85rem;
        p{
            min-height: auto;
        }
        a{            
            color: #00095E;
        }
    }
}
.products_swiper{
    .swiper-slide>div{
        border: 1px solid #dee2e6;
        border-radius: 1rem;
        height: 350px;
        padding: 5px;
    }
    @include media-breakpoint-up(md) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .swiper-slide>div{
          width: 250px;
          margin: 0 auto;
        }
    }
        /*
        .swiper-wrapper {
          display: flex;
          gap: 24px;
        }
        .swiper-slide {
          width: calc(25% - 18px);
        }
        */
    .swiper_container{
        position: relative;
    }
    .swiper-pagination {
        position: relative !important;
        margin-top: 20px !important;
    }
}
.caption_underline{
    position: relative;
    padding-bottom: 56px;
    text-align: center;
    &::after{
        background-color: #FFC002;
        bottom: 30px;
        content: "";
        height: 3px;
        left: 50%;
        line-height: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 40px;
    }
}
.caption-Left_underline{
    position: relative;
    padding-bottom: 25px;
    &:after{
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background-color: #FFC002;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
.caption_marker_orange{
    background: linear-gradient(transparent 50%, #FFC002 50%);
}
.caption_marker_blue{
    background: linear-gradient(transparent 50%, #4681bf 50%);
}
.caption_marker_lightblue{
    background: linear-gradient(transparent 50%, #86B6F6 50%);
}
.caption_marker_skyblue{
    background: linear-gradient(transparent 50%, #b3cff3 50%);
}
.caption_marker_yellow{
    background: linear-gradient(transparent 50%, #fffb02 50%);
}
.fukidasi{
    position: relative;
    width: 100%;
    padding: 6px 20px;
    color: #000;
    border-radius: 10px;
    background-color: #f6f6f6;
    @media screen and (max-width: 780px){
        padding: 6px 10px;
    }
}
.fukidasi:before {
    content: "";
    position: absolute;
    top: calc(100% - 1px); /* 1px上にずらす */
    left: 50%;
    z-index: -1;
    width: 20px;
    height: 12px;
    transform: translateX(-50%);
    box-sizing: border-box;
    background-color: #f6f6f6;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    box-sizing: border-box;
}
.nayami_swiper{
    .swiper-slide>div{
        height: 500px;
        padding: 5px;
    }
    @include media-breakpoint-down(md) {
        height: 550px;
    }
    @include media-breakpoint-down(sm) {
        height: 500px;
        .swiper-slide>div{
          width: 250px;
          margin: 0 auto;
        }
    }
    @include media-breakpoint-down(xs) {
        .swiper-slide>div{
          width: 70%;
          min-width: 250px;
          margin: 0 auto;
        }
    }
    @include media-breakpoint-up(md) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
    }
    .swiper_container{
        position: relative;
    }
    .swiper-pagination {
        position: relative !important;
        margin-top: 20px !important;
    }
}
.nayami_section{
    background: linear-gradient(0deg, #FFF4CE 57%, #fff 0);
    @media screen and (max-width: 1000px){
        background: linear-gradient(0deg, #FFF4CE 60%, #fff 0);
    }
    @media screen and (max-width: 600px){
        background: linear-gradient(0deg, #FFF4CE 63%, #fff 0);
    }
    position: relative;
    h4{
        position: absolute;
        top: 250px;
        left:50%;
        background-color: #FFF4CE;
        border-radius: 20px;
        transform: translateX(-50%);
        width: 600px;
        z-index: 100;
        font-weight: bold;
        @media screen and (max-width: 767px){
            top: 220px;
            width: 80%;
        }
        @media screen and (max-width: 575px){
            top: 210px;
            width: 80%;
        }
    }
    .solution_caption {
        text-align: center;
        padding-bottom: 1rem;
        padding-top: 3rem;
        @include media-breakpoint-down(md) {
            padding-top: 5rem;
        }
        span{
            font-size:1.35rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
            line-height: 1.2;
            color: #FF6200;
            background: linear-gradient(transparent 50%, #fffb02 50%);
            @include media-breakpoint-only(md) {
                font-size: 2vw;
            }
        }
    }
}
/*文字サイズを通常より少しだけ大きくする*/
.font-big{
    font-size: 16px;
}
/*出荷番*/
.syukkaban_keyphrase{
    font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    position: relative;
    color:#ffffff;
    background-color:rgba(44,62,80);
    @include media-breakpoint-up(xxl) {
        top: 5%;
    };
    @include media-breakpoint-only(xl) {
        top: 5%;
    };
    @include media-breakpoint-only(lg) {
        top: 8%;
    };
    @include media-breakpoint-only(md) {
        top: 8%;
    };
    @include media-breakpoint-only(sm) {
        top: 5%;
    };
    @include media-breakpoint-only(xs) {
        top: 5%;
    };
}
.syukkaban_keyphrase h1{
    font-weight: bold;
    color:#f1f1f1;
    position: relative;
    text-align: center;    
    line-height: 150%;
    @include media-breakpoint-up(xxl) {
        font-size: 3em;
    };
    @include media-breakpoint-between(xl,lg) {
        font-size: 3vw;
    };
    @include media-breakpoint-only(md) {
        font-size: 3.5vw;
    };
    @include media-breakpoint-down(sm) {
        font-size: 6.0vw;
    };
}
.syukkaban_keyphrase  h2{
    font-weight: bold;
    color:#eeeeee;
    position: relative;
    line-height: 1.5;
    @include media-breakpoint-up(xxl) {
        padding-top: 30px;
        font-size: 19px;        
    };
    @include media-breakpoint-only(xl) {
        padding-top: 2vw;
        font-size: 1.3vw;        
    };
    @include media-breakpoint-only(lg) {
        padding-top: 1.8vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(md) {
        padding-top: 2vw;
        font-size: 1.5vw;        
    };
    @include media-breakpoint-only(sm) {
        padding-top: 2vw;
        font-size: 2.8vw;        
    };
    @include media-breakpoint-only(xs) {
        padding-top: 2vw;
        font-size: 3vw;        
    };
}
.shukkaban_underline{
    position: relative;
    padding-bottom: 40px;
    text-align: center;
    &::after{
        background-color: #4681bf;
        bottom: 20px;
        content: "";
        height: 3px;
        left: 50%;
        line-height: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 40px;
    }
}
.shukkaban-Left_underline{
    position: relative;
    padding-bottom: 25px;
    &:after{
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background-color: #4681bf;
        position: absolute;
        bottom: 0;
        left: 0;
    };
}
.shukkaban-Kinou{
    display: flex;
    justify-content: center;
    align-items:stretch;
    flex-wrap: nowrap;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
}
.shukkaban-Kinou div{
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #032962;
    width: 300px; 
    margin-right: 10px;
    @include media-breakpoint-down(sm) {
        margin-top: 10px;
        width: 45%;  
    }
    p{
        height: 70px;
        color:#031D62;
        font-size: 1.1rem;
        margin-bottom: 10px;
    }
    
}
.shukkaban-Kinou div:last-child {
    @include media-breakpoint-up(md) {
        margin-right: 0px;
    }    
}
.syukka_swiper{
    .swiper-slide>div{
        border: 1px solid #dee2e6;
        border-radius: 0;
        height: 400px;
        padding: 5px;
        @include media-breakpoint-down(md) {
            height: 450px;
        }
        @include media-breakpoint-down(sm) {
            width: 250px;
            margin: 0 auto;
        }
    }    
    @include media-breakpoint-up(md) {
        .swiper-button-prev, .swiper-button-next {
          display: none;
        }
    }
    span{
        font-size: 1.35rem;
        @include media-breakpoint-only(md) {
            font-size: 2.2vw;
        }
    }
    .swiper_container{
        position: relative;
    }
    .swiper-pagination {
        position: relative !important;
        margin-top: 20px !important;
    }
    .swiper-pagination-bullet {
        height: 15px !important;;
        width: 15px !important;;
      }
}
h4.shukkaban_usedcaption {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    border: 3px solid #4681BF;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    background-color: white;
}
.shukkaban_usedtable{
    border-top: none;
    border-bottom: 3px solid #4681BF;
    border-left: 3px solid #4681BF;
    border-right: 3px solid #4681BF;
    background-color: white;
    .used-row{
        >div:not(:last-child) {
            @include media-breakpoint-up(sm) {
                border-right: 3px solid #4681BF;    
            };
            @include media-breakpoint-down(xs) {
                border-bottom: 3px solid #4681BF;    
            };
        };
        &:not(:last-child) {
            border-bottom: 3px solid #4681BF;   
        }
    }
}
.shukkaban_usedtable div{
    font-size: 1.5vh;
    @include media-breakpoint-only(sm) {
        font-size: 1.0vh;
    };
}
.Shukka_catalog-DL_button{
    background-color: #4681BF;
    color: white;    
    border-radius:8px;
    font-weight: bold;
    border: solid 3px #4681BF;
    @include media-breakpoint-only(sm) {
        padding: 10px 0 10px 0;
        font-size: 4vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    }
    @include media-breakpoint-down(xs) {
        padding: 10px 0 10px 0;
        font-size: 5vw;
        width: 100%;
        clear:both;
        text-align: center;
        border: 3px solid #ffffff;
    }
}