/* 私たちは、お客さまの抱える問題点や課題を～ */
#dindex-title{
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-align: center;
}
/* 受託開発とはの枠 */
.what_jutaku{
    border: 1px solid;
    border-radius: 5px;
    border-color: #afafaf;
    background-color: white;
    width: 600px;
    margin: 0 auto 24px auto;
    padding: 24px 0 24px 0;
    /* col-sm 767px以下 */
    @media screen and (max-width: 767px){
        width: 80vw;
    }
    /* col-sm 575px以下 */
    @media screen and (max-width: 575px){
        width: 100%;
    }
}
/* 受託開発とはのタイトル */
.what_jutaku_title{
    font-size:18px;
}
.what_jutaku_title a{
    font-size:22px;
}
/* 開発実績の枠 */
.dev-item{
    width: 970px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    border-radius: 5px;
    border-color: #afafaf;
    background-color: white;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 16px;
    padding-bottom: 16px;
    li{
        white-space: normal;
        /*float: left;*/
        width: 100%;
        height: auto;
        word-wrap: break-word;
    }
}
/* col-md 991px以下 */
@media screen and (max-width: 991px){
    .dev-item{
        width: 95vw;
    }
}
.dev-img{
    text-align: center;
}
.dev-text{
    max-width: 550px;
}
.dev-img > img{
    width: 300px;
    height:auto;
}
.dev-title{
    font-size:1.3rem;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}
.dev-before{
    font-size:0.9rem;
    font-weight:200;
}
.dev-after{
    font-size:0.9rem;
    font-weight:200;
}
.dev-summary{
    font-size:0.9rem;
    font-weight:200;
}
.develop_group_menu{
    width: 970px;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}
.develop-group-btn{
    position: relative;
}
#system_menu , #work_menu{
    padding:0;
}
#system_menu ul,#system_menu li, #work_menu ul,#work_menu li{
    margin:0;
    padding:0;
    list-style: none;
}
#system_menu > li,#work_menu > li{
    border: rgb(15, 101, 182) 1px solid;
    margin-bottom: -1px;
    background-color: #bee2ff;
    font-size: 1.2rem;
}
#system_menu a, #work_menu a{
    color: rgb(12, 3, 39);
    text-align: center;
}
#system_menu a[data-toggle="collapse"],#work_menu a[data-toggle="collapse"]{
    display: block;
    padding: 10px;
    text-decoration: none;
    position: relative;
}
#system_menu a[data-toggle="collapse"]:hover,#work_menu a[data-toggle="collapse"]:hover{
    background: #8bc7f8;
}
#system_menu a[data-toggle="collapse"]::after,#work_menu a[data-toggle="collapse"]::after{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    top: 8px;
    right: 20px;
    color: #1563f1;
    content: "\f055";
}
#system_menu a[aria-expanded=false]::after,#work_menu a[aria-expanded=false]::after{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    top: 10px;
    right: 20px;
    color: #1563f1;
    content: "\f055";
}
#system_menu a[aria-expanded=true]::after,#work_menu a[aria-expanded=true]::after{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    top: 10px;
    right: 20px;
    color: #1563f1;
    content: "\f056";
}
#menu01,#menu02 {
    border: rgb(15, 101, 182) 1px solid;
}
#menu01 li,#menu02 li{
    padding: 5px 5px 5px 20px;
    border-top: rgb(15, 101, 182) 1px solid;
    /* col-sm 767px以下 */
    @media screen and (max-width: 767px){
        padding: 10px 10px 10px 20px;
    }
}
