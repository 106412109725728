html{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
    position: relative;
}
body{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
}
img {
    aspect-ratio: attr(width) / attr(height);
}
/* リンクの色（Google準拠） */
a {
    color:#1a0dab;
    @include media-breakpoint-down(xs) {
        /* スマホ */
        color:#1558d6;
    }
}
a:hover{
    color:#1a0dab;
    @include media-breakpoint-down(xs) {
        /* スマホ */
        color:#1558d6;
    }
}
.main-content{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
}
picture.img-fluid > img {
    max-width: 100%;
    height: auto;
}
.youtube_iframe_image{
    position: relative;
    background-color: #fff;
    max-width: 560px;
}
.youtube_iframe_image a{
    border: 2px solid #fff;
    display: block;
}
.youtube_iframe_image a:hover{
    opacity: 0.6;
}
.youtube_iframe_image i {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    margin:0;
    padding:0;
    color: red;
    font-size: 60px;
}
.youtube_iframe_image a img {
    width: 100%;
}
/*　ヘッダー部　*/
.text-s{
    font-size: x-small;
}
.k-font{
    font-size: large;
    font-weight: bold;
}
.n-color{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
    min-height: 55px;
    border: 1px solid transparent;
    color: rgb(54, 54, 54);
}
#Navbar{
    font-weight: 600;
}
.site-logo{
    height:45px;
    width:auto;
    image-rendering: crisp-edges;
    -webkit-backface-visibility: hidden;
}
#Navbar div.dropdown > a{
    color: rgb(54, 54, 54);
}
#Navbar div.menu_item > div.dropdown-menu > a.dropdown-item{
    font-size: 14px;
}
.youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}
.youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
/* ページトップへ */
#page_top{
    width: 50px;
    height: 50px;
    position: fixed;
    right: 90px;
    bottom: 10px;
    background: #aaa;
    opacity: 0.6;
    border-radius: 50%;
}
#page_top a{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    text-decoration: none;
}
#page_top a::before{
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f102';
    font-size: 25px;
    color: #fff;
    position: absolute;
    width: 25px;
    height: 25px;
    top: -15px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
}

/* お問い合わせへ */
#to_contact{
    @media screen and (min-width: 1500px) {
        position: sticky;
        top: 0px;
        margin-right:0;
        margin-left: auto;    
        width: 269px;    
    }
    @media screen and (min-width: 1400px) and (max-width:1499px) {
        position: sticky;
        top: 0px;
        margin-right:0;
        margin-left: auto;    
        width: 239px;    
    }
    @media screen and (min-width: 1200px) and (max-width:1399px) {
        position: sticky;
        top: 0px;
        margin-right:0;
        margin-left: auto;    
        width: 233px;    
    }
    @media screen and (min-width: 992px) and (max-width:1199px) {
        position: sticky;
        top: 0px;
        margin-right:0;
        margin-left: auto;    
        width: 227px;    
    }
    @media screen and (min-width: 577px) and (max-width:991px) {
        position: fixed;
        bottom: 0px;
        right: 0px;  
        width: 227px;   
    }
    @media screen and (max-width: 576px) {
        position: fixed;
        bottom: 0px;
        left: 0px;  
        width: 100%;   
    }
    height: 80px;
    z-index: 2000;
}
#to_contact a{
    position: relative;
    background: #00A0E9;
    border-radius: 0;
    border: white solid 1px;
    display: block;
    color: white;
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    text-decoration: none;
    z-index: 2000;
    font-size: 1.5em;
    @media screen and (min-width: 1500px) {
        font-size: 1.2em;
    }
}
#to_contact a::before{
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f0e0';
    color: #fff;
    position: absolute;
    top: -2px;
    left: 15px;
    margin: auto;
    text-align: left;
    z-index: 2000;
    font-size: 1em;
    @media screen and (min-width: 1500px) {
        font-size: 1.2em;
    }
}
#to_contact a::after{
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f101';
    color: #fff;
    position: absolute;
    top: -2px;
    right: 15px;
    margin: auto;
    text-align: right;
    z-index: 2000;
    font-size: 1em;
    @media screen and (min-width: 1500px) {
        font-size: 1.2em;
    }
}
.home-key-visial {
    padding-top: 20px;
    @include media-breakpoint-only(xs) { height: 72vw; }
    @include media-breakpoint-only(sm) { height: 28vw; }
    @include media-breakpoint-only(md) { height: 32vw; }
    @include media-breakpoint-only(lg) { height: 31vw; }
    @include media-breakpoint-only(xl) { height: 30vw; }
    @include media-breakpoint-only(xxl) { height: 450px; }
}
/* フッター部 */
footer{
    max-width: 1500px;
    margin-left:auto;
    margin-right:auto;
    background-color: #0f62c1;
    background-repeat: repeat;
 /*    background-color: #1F6BFF;*/
    color: white;
}
.text-dark-blue{
    color: #1F6BFF;
}
#Footer{
    max-width: 1000px;
}
#Footer a{
    color: white;
}
footer .badge{
    font-size: 11.5px;
    padding-top: 6px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
}
.footer_tel{
    font-weight: 600;
    font-size: 30px;
}
.footer_fax{
    font-weight: 600;
    font-size: 20px;
}
footer ul{
    list-style: none;
    padding: 0;
    font-size:13px;
    margin-bottom: 0;
}
footer li{
    position: relative;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-left: 10px;
}
footer li::before{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F0DA";
    top: 0;
    left: -10px;
}
footer li a{
    color:white;
}

/* パッケージ2列目の設定 */
footer .noicon::before{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: none;
    top: 0;
    left: -10px;
}
footer .ptop{
    @include media-breakpoint-down(sm) {
        display: none!important;
    }
}
footer .package-pb{
    padding-top: 10px;
    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }
}

/* フッター最下部 */
.copyright{
    text-align: center;
    background-color: #1e3b7e;
    height: 40px;
    font-size: 12px;
}
/* 質問をなげる */
.question-bg{
    background-color: #ffeebf;
}
.question_image{
    background-color: #fbfbbd;
    max-width: 400px;
    text-wrap:normal;
    font-size:1rem;
}
.question-title{
    position: relative;
}
.question-title h1{
    position: absolute;
    /* Yu Gothic Bold 89px 49.0 1.0 */
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:black;
    @include media-breakpoint-down(xs) {
        top: 5vw;
        left: 0;
        font-size: 6vw;
        padding-left: 1rem;
    }
    @include media-breakpoint-between(sm, md) {
        top: 5.5vw;
        left: 0;
        font-size: 2rem;
        padding-left: 10vw;
    };
    @include media-breakpoint-between(lg, xl) {
        top: 10vw;
        left: 8vw;
        font-size: 3vw;
        padding-left: 50px;
    };
    @include media-breakpoint-up(xxl) {
        top: 125px;
        left: 250px;
        font-size: 3.5rem;
    }
}
#question-button{
    border: 2px solid #588dff;
}

/* お問い合せ */
.contact-bg{
   background-color: rgba(89,139,254, 0.6);
}
.contact-title{
    position: relative;
}
.contact-title h1{
    position: absolute;
    /* Yu Gothic Bold 89px 49.0 1.0 */
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:black;
    @include media-breakpoint-down(xs) {
        top: 5vw;
        left: 0;
        font-size: 6vw;
        padding-left: 1rem;
    }
    @include media-breakpoint-between(sm, md) {
        top: 5.5vw;
        left: 0;
        font-size: 2rem;
        padding-left: 10vw;
    };
    @include media-breakpoint-between(lg, xl) {
        top: 10vw;
        left: 8vw;
        font-size: 3vw;
        padding-left: 50px;
    };
    @include media-breakpoint-up(xxl) {
        top: 125px;
        left: 250px;
        font-size: 3.5rem;
    }
}

/* オンライン相談予約 */
.yoyaku-bg{
    background-color: rgba(89,139,254, 0.6);
 }
 .nagare{
    display: block;
    text-align: center;
}
 .yoyaku-title{
     position: relative;
 }
 .yoyaku-title h1{
     position: absolute;
     /* Yu Gothic Bold 89px 49.0 1.0 */
     font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
     font-weight: bold;
     color:black;
     @include media-breakpoint-down(xs) {
         top: 20px;
         left: 0;
         font-size: 6vw;
         padding-left: 1rem;
     }
     @include media-breakpoint-between(sm, md) {
         top: 7vw;
         left: 0;
         font-size: 2rem;
         padding-left: 10vw;
     };
     @include media-breakpoint-between(lg, xl) {
         top: 9.5vw;
         left: 8vw;
         font-size: 3vw;
         padding-left: 50px;
     };
     @include media-breakpoint-up(xxl) {
         top: 125px;
         left: 250px;
         font-size: 3.5rem;
     }
 }
 
/* ダウンロードいただきありがとうございます */
.dl-thanks-bg{
    padding: 0 24px 48px 24px;
/*    background-image: url('https://https://xb938209.xbiz.jp/img/thanks.jpg');*/
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-color  : #fff;
    background-blend-mode : screen;
}
.dl-thanks-title h1{
    position: absolute;
    /* Yu Gothic Bold 89px 49.0 1.0 */
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
    font-weight: bold;
    color:black;
    top: 15rem;
    left: 0;
    font-size: 3.5rem;
    padding-left: 10rem;
}
/* 830 */
.w-830{
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: 0 auto;
}
/* ＼文字／ */
.sectTit {
    position: relative;
    text-align: center;
    font-weight: bold;
    line-height: 1.3;
    font-size: 1rem;
}
.sectTit:before {
    position: relative;
    display: inline-block;
    content: "";
    background: #838383;
    width: 2px;
    height: 2em;
    margin: 0 1em;
    margin-top: -.2em;
    vertical-align: middle;
    transform: rotate(-35deg);
}
.sectTit:after {
    position: relative;
    display: inline-block;
    content: "";
    background: #838383;
    width: 2px;
    height: 2em;
    margin: 0 0.9em;
    margin-top: -.2em;
    vertical-align: middle;
    transform: rotate(36deg);
}

.sectTit.white:before,
.sectTit.white:after {
    background: white;
}
   
/* お問い合わせエリア サブタイトル */
.contact-area-subtitle {
    text-align: center;
    font-weight: bold;
    line-height: 1.3;
    font-size: 1rem;
}
/* col-md 991px以下 */
@media screen and (max-width: 991px){
    .absolute-top{
        position: static;
    }
    .n-color{
        background-color: rgba(255, 255, 255, 1);
    }
    .site-logo{
        height: 40px;
        image-rendering: crisp-edges;
        -webkit-backface-visibility: hidden;
    }
    #Navbar div.menu_item{
        text-align: left;
        padding-left: 10px;
        border-top: 1px solid rgb(223, 223, 223);
    }
    #Navbar div.menu_item_parent{
        text-align: left;
        padding-left: 10px;
        border-top: 1px solid rgb(223, 223, 223);
    }
    #Navbar div.menu_item_child{
        text-align: left;
        padding-left: 30px;
        padding-right: 10px;
        border-top: 1px solid rgb(223, 223, 223);
    }
    #Navbar div.linkbox > div,#Navbar div.dropdown > a{
        font-size:1rem;
        display: inline-block;
    }
    #Navbar div.dropdown > a{
        padding: 0;
    }
    .contact_link{
        background-color: #598bfe;
        color: white;
        margin: 0 -10px;
    }
    .contact_icon{
        margin-left:10px;
        margin-right:10px;
        width: 15px;
        height: auto;
    }
    .tel_link{
        background-color: #CFDFF0;
        color: #000D99;
        margin: 0 -10px;
    }
    .tel_icon{
        margin-left:10px;
        margin-right:10px;
        width: 15px;
        height: auto;
    }
    .w-830{
        width: 700px;
    }
    /* フッター部 */
    .copyright{
        font-size: 13px;
    }
    /* お問い合せ */
    .contact-bg{
        padding: 0 24px 48px 24px;
    }
    /* 質問を投げる */
    .question-bg{
        padding: 0 24px 48px 24px;
    }
    /* オンライン相談 */
    .yoyaku-bg{
        padding: 0 24px 48px 24px;
    }
}
/* col-sm 767px以下 */
@media screen and (max-width: 767px){
    .w-830{
        width: 100%;
    }
    /* フッター部 */
    .menu{
        display: inline-block;
        text-align: left;
    }
    .copyright{
        font-size: 11px;
    }
    /* お問い合せ */
    .contact-bg{
        padding: 0 24px 48px 24px;
    }
    /* 質問を投げる */
    .question-bg{
        padding: 0 24px 48px 24px;
    }
    /* オンライン相談 */
    .yoyaku-bg{
        padding: 0 24px 48px 24px;
    }
}

/* col-sm 575px以下 */
@media screen and (max-width: 575px){
    .w-830{
        width: 100%;
    }
    /* お問い合せ */
    .contact-bg{
        padding: 0 10px 48px 10px;
    }
    /* 質問を投げる */
    .question-bg{
        padding: 0 10px 48px 10px;
    }
    /* オンライン相談 */
    .yoyaku-bg{
        padding: 0 10px 48px 10px;
    }
}
/* col-lg 992px～1200px */
@media screen and (min-width: 992px) and (max-width: 1200px){
    #Navbar{
        font-size: 14px;
    }
}
/* col-xl 1200~1500 */
@media screen and (min-width: 1200px) and (max-width: 1500px){
    #Navbar{
        font-size: 15px;
    }
}
/* col-md 992px～1500px */
@media screen and (min-width: 992px) and (max-width: 1500px){
    /* ヘッダー部 */
    .absolute-top{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index:1500;
    }
    .n-color{
        background-color: rgba(245, 252, 255, 0.678);
        height: 80px;
    }
    .contact_link{
        height: 80px;
        background-color: #598bfe;
    }
    .tel_link{
        height: 80px;
        background-color: #CFDFF0;
        color: #000D99;
    }
    .navbar{
        padding-right:0;
    }
    #Navbar div.menu_item{
        text-align: center;
        vertical-align: middle;
        padding-left: 1vw;
        padding-right: 1vw;
        border-top: 0px;
        height: 80px;
    }
    #Navbar div.menu_item:first-child:before{
        content:"";
        display:inline-block;
        width: 1px;
        height: 25px;
        background-color:#588dff;
        position:absolute;
        top: 25px;
        left: 0;
    }
    #Navbar div.menu_item:not(:last-child)::after{
        content:"";
        display:inline-block;
        width: 1px;
        height: 25px;
        background-color:#588dff;
        position:absolute;
        top: 25px;
        left: 0;
    }
    #Navbar div.tel_link::after{
        display: none;
    }
    #Navbar div.linkbox > div{
        margin-top: 25px;
        display: block;
    }
    #Navbar div.dropdown > a{
        margin-top: 15px;
        display: block;
        padding: 10px;
    }
    #Navbar div.contact_link {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
        color: #FFF;
    }
    #Navbar div.contact_link > img{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #Navbar div.contact_link > div{
        margin-top: 0;
    }
    #Navbar div.tel_link {
        margin-left: 0;
        padding-left: 5px;
        padding-right: 5px;
        color: #000D99;
        font-size: 16px;
    }
    #Navbar div.tel_link > i{
        /*margin-top: 10px;*/
        margin-top: 5px;
        margin-bottom: 10px;
    }
    #Navbar div.tel_link a{
        color: #000D99;
    }
    #Navbar div.tel_link > div{
        margin-top: 0;
    }
    .w-830{
        width: 830px;
    }
    .contact_icon{
        width: auto;
        height: 18px;
    }
    /* お問い合せ */
    .contact-bg{
        padding: 0 24px 48px 24px;
    }
    /* 質問を投げる */
    .question-bg{
        padding: 0 24px 48px 24px;
    }
    /* オンライン相談 */
    .yoyaku-bg{
        padding: 0 24px 48px 24px;
    }
}
@media screen and (min-width: 575px) and (max-width: 1499px){

}
/* 1500px～ */
@media screen and (min-width: 1500px){
    /* ヘッダー部 */
    .absolute-top{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index:1500;
    }
    .n-color{
        /*background-color: rgba(255, 255, 255, 0.678);*/
        background-color: rgba(245, 252, 255, 0.678);
        height: 80px;
    }
    .contact_link{
        height: 80px;
        background-color: #598bfe;
    }
    .tel_link{
        height: 80px;
        background-color: #CFDFF0;
    }
    .navbar{
        padding-right:0;
    }
    #Navbar{
        font-size: 15px;
    }
    #Navbar div.menu_item{
        text-align: center;
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;
        border-top: 0px;
        height: 80px;
    }
    #Navbar div.menu_item:first-child:before{
        content:"";
        display:inline-block;
        width: 1px;
        height: 25px;
        background-color:#588dff;
        position:absolute;
        top: 25px;
        left: 0;
    }
    #Navbar div.menu_item:not(:last-child)::after{
        content:"";
        display:inline-block;
        width: 1px;
        height: 25px;
        background-color:#588dff;
        position:absolute;
        top: 25px;
        left: 0;
    }
    #Navbar div.linkbox > div{
        margin-top: 25px;
        display: block;
    }
    #Navbar div.dropdown > a{
        margin-top: 15px;
        display: block;
        padding: 10px;
    }
    #Navbar div.contact_link {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
        color: #FFF;
    }
    #Navbar div.contact_link > img{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #Navbar div.contact_link > div{
        margin-top: 0;
    }
    #Navbar div.tel_link {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
        color: #000D99;
    }
    #Navbar div.tel_link > i{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #Navbar div.tel_link > div{
        margin-top: 0;
    }
    .w-830{
        width: 830px;
    }
    .contact_icon{
        width: auto;
        height: 18px;
    }
    /* お問い合せ */
    .contact-bg{
        padding: 0 24px 48px 24px;
    }
    /* 質問を投げる */
    .question-bg{
        padding: 0 24px 48px 24px;
    }
     /* オンライン相談予約 */
    .yoyaku-bg{
        padding: 0 24px 48px 24px;
    }
}

@media screen and (min-width: 992px){
    #Navbar div.menu_item_parent {
        text-align: center;
        vertical-align: middle;
        padding-left: 1vw;
        padding-right: 1vw;
        border-top: 0px;
        height: 80px;
        ::after{
            content: "";
            display: inline-block;
            width: 1px;
            height: 25px;
            background-color: #588dff;
            position: absolute;
            top: 25px;
            left: 0;
        }
    }
    .menu_item_group {
        position:  relative;        /* 要素の配置方法をと子要素の起点を指定 */
    }
    .menu_item_childs{
        display: none;/*デフォルトでは非表示の状態にしておく*/
        min-width: 130%;
        position: absolute;
        top: 80px;
        left: 0;
    }
    #Navbar .menu_item_child.linkbox > div {
        margin-top: 0;
    }
    .menu_item_child{
        background-color: white;
        text-align: center;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        /*height: 60px;*/
        transition: all .3s;
        position: relative;
        font-size:12px;
        border-bottom: 1px;
        border-color: #588dff;
        white-space: nowrap;
    }
    .menu_item_group:hover{
        .menu_item_childs{
            display: block;
            background-color: white;
        }
    }
}
/* お問い合わせボタン2つ */
.contact-area-bg{
    background-color: #F6E7FD;
}
.contact-area div{
    width: 970px;
    margin-left: auto;
    margin-right: auto;
    /* col-md 991px以下 */
    @media screen and (max-width: 991px){
        width: 95vw;
    }
    a.contact_button,a.yoyaku_button{
        color: #6C6C6C;
        border: 2px solid #6C6C6C;
        background-color: white;
        :hover{
            color:#6C6C6C;
            background-color: #f0f0f0;
        }
    }
    a.download_button{
        font-weight: 900;
        position: relative;
    }
    a.download_button::after{
        position: absolute;
        top: 50%;
        right: 18px;
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-top: -9px;            
        vertical-align: middle;
    }
}
.transparent45{
    background-color: rgba( 255, 255, 255, 0.45 );
}
.hr{
    width:90%;
    height:1px;
    background-color:gray;
    margin-top:5px;
    margin-bottom:5px;
    margin-left:auto;
    margin-right:auto;
}
/* 斜め線 */
.attention {
    position: relative;
    display: inline-block;
    padding: 0 20px;
}
.attention:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 1px;
    height: 100%;
    background: #000;
    border-radius: 3px;
    transform: rotate(-35deg);
}
.attention:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: #000;
    border-radius: 3px;
    transform: rotate(35deg);
}

/* クッキー同意 */
.cookie-consent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    color: #fff;
    background: rgba(0,0,0,.7);
    padding: 1.2em;
    box-sizing: border-box;
    visibility: hidden;
}
.cookie-consent.is-show {
    visibility: visible;
    z-index: 2500;
}
.policy-link a{
    color: white;
    font-size: 15px;
    text-decoration: underline;
}
.cookie-agree, .cookie-reject {
    color: #fff;
    background: dodgerblue;
    padding: .5em 1.5em;
    margin-left: 20px;
    &:hover{
        cursor: pointer;
    }
}
/* パッと消える */
.cc-hide1 {
    display: none;
}
/* ゆっくり消える */
.cc-hide2 {
    animation: hide 1s linear 0s;
    animation-fill-mode: forwards;
}
@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}
/* メディアクエリ */
@media screen and (max-width: 600px) {
    .cookie-consent {
        flex-direction: column;
    }
    .cookie-text {
        margin-bottom: 1em;
    }
}
/* サブシステムのすすめ */
.subsys-riten{
    padding: 40px;
    @media screen and (max-width: 768px) {
        padding: 40px 20px;
    }
}
.subsys-nayami{
    padding: 0 40px 0;
    @media screen and (max-width: 768px) {
        padding:  0 20px 0;
    }
}
/*ITトレンドEXPO*/
.eventInfo{
    padding: 40px 40px 0px 0px;
    @media screen and (max-width: 768px) {
        padding: 30px 0px 0px 0px;
    }
}
.eventInfo > h5{
    @media screen and (max-width: 576px) {
        font-size: 14px;
    }
}
.event_detail_button{
    background-color: #da4733;
    color: white;
    border-radius:8px;
    font-weight: bold;
    /*text-shadow: -1px -1px 0 white, -1px 0 0 white, -1px 1px 0 white,
                    0 -1px 0 white,                  0 1px 0 white,
                  1px -1px 0 white,  1px 0 0 white,  1px 1px 0 white;*/
    border: solid 3px #da4733;
    text-align: center;
    @include media-breakpoint-up(xxl) {
        padding: 5px 15px 5px 15px;
        font-size: 1.2rem;
        width: 450px;
    };
    @include media-breakpoint-only(xl) {
        padding: 5px 15px 5px 15px;
        font-size: 1.2rem;
        width: 450px;
    };
    @include media-breakpoint-only(lg) {
        padding: 5px 15px 5px 15px;
        font-size: 1.2rem;
        width: 450px;
    };
    @include media-breakpoint-only(md) {
        padding: 5px 0 5px 0;
        font-size: 1.2rem;
        width: 450px;
    };
    @include media-breakpoint-only(sm) {
        padding: 5px 0 5px 0;
        font-size: 3vw;
        width: 80%;
    };
    @include media-breakpoint-only(xs) {
        padding: 3px 0 3px 0;
        font-size: 3vw;
        width: 80%;
        clear:both;
    };
}