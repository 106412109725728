
.throw_navi_area > div{
    width: 840px;
    margin-left: auto;
    margin-right: auto;
    /* col-md 991px以下 */
    @media screen and (max-width: 991px){
        width: 95vw;
    }
}
/*--------------------------------------
 吹き出し（中央寄せ）
--------------------------------------*/
.throw_navi_area .btn_yoyaku,
.throw_navi_area .btn_question {
    font-size: 1.15rem;
    width: 100%;
    position: relative;
    padding: 0 1.5rem 0.5rem 1.3rem;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    letter-spacing: 0.1em;
    border-radius: 0.5rem;
    &:hover {
        -webkit-transform: translate(0, 3px);
        transform: translate(0, 3px);
        color: #fff;
    }
    i {
        margin-right: 0.75rem;
    }
    span {
        font-size: 0.8rem;
        position: absolute;
        top: -0.75rem;
        left: calc(50% - 170px);
        display: block;
        width: 340px;
        padding: 0.25rem 0;
        border-radius: 100vh;
        background: #fff;
        -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
        ::before,::after {
            position: absolute;
            left: calc(50% - 10px);
            content: "";
        }
        ::before {
            bottom: -10px;
            border-width: 10px 10px 0 10px;
            border-style: solid;
        }
        ::after {
            bottom: -7px;
            border-width: 10px 10px 0 10px;
            border-style: solid;
            border-color: #fff transparent transparent transparent;
        }
    }
}
.throw_navi_area a.btn_yoyaku {
    color: #fff;
    background: #2668fe;
    -webkit-box-shadow: 0 5px 0 #1148c3;
    box-shadow: 0 5px 0 #1148c3;
    &:hover {
        color: #fff;
        background: #2668fe;
        -webkit-box-shadow: 0 2px 0 #1148c3;
        box-shadow: 0 2px 0 #1148c3;
    }
    span {
        color: #1148c3;
        border: 2px solid #1148c3;
        ::before {
            border-color: #1148c3 transparent transparent transparent;
        }
        ::after {
            border-color: #fff transparent transparent transparent;
        }
    }
}
.throw_navi_area a.btn_question {
    color: #212529;
    background: rgb(255,238,191);
    -webkit-box-shadow: 0 5px 0 #f8d36e;
    box-shadow: 0 5px 0 #f8d36e;
    &:hover {
        color: #212529;
        background: #f8d36e;
        -webkit-box-shadow: 0 2px 0 #f8d36e;
        box-shadow: 0 2px 0 #f8d36e;
    }
    span {
        color: #212529;
        border: 2px solid #f8d36e;
        ::before {
            border-color: #f8d36e transparent transparent transparent;
        }
        ::after {
            border-color: #fff transparent transparent transparent;
        }
    }
}
